import { PureComponent } from 'react';

type TrashWithColorProps = {
    color: string;
    title?: string;
}

export class TrashWithColor extends PureComponent<TrashWithColorProps> {
    render() {
        const { color, title = 'Icons/trash' } = this.props;

        return (
            <svg width="42px" height="42px" viewBox="0 0 42 42">
                <title>{title}</title>
                <g id="Icons/trash" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="Rectangle"  stroke={color}  fill="#FFFFFF" opacity="0" x="0" y="0" width="42" height="42" rx="5"></rect>
                    <g id="trash-2" transform="translate(12.000000, 11.000000)" stroke="#111111" strokeLinecap="round" strokeLinejoin="round">
                        <polyline id="Path" stroke={color}  points="0 4 2 4 18 4"></polyline>
                        <path  stroke={color}  d="M16,4 L16,18 C16,19.1045695 15.1045695,20 14,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,4 M5,4 L5,2 C5,0.8954305 5.8954305,0 7,0 L11,0 C12.1045695,0 13,0.8954305 13,2 L13,4" id="Shape"></path>
                        <line  stroke={color}  x1="7" y1="9" x2="7" y2="15" id="Path"></line>
                        <line  stroke={color}  x1="11" y1="9" x2="11" y2="15" id="Path"></line>
                    </g>
                </g>
            </svg>
        );
    }
}