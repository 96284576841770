import { Observable } from '@babylonjs/core/Misc/observable';
import { ComponentReference } from '../../types';


type FileEventManagementReference = ComponentReference | Record<string, any> | undefined;

export type FileEventManagementOnFileAdded<T = any> = {
    reference: FileEventManagementReference;
    data: T;
}

export type FileEventManagementOnFileDeleted<T = any> = {
    reference: FileEventManagementReference;
    data: T;
}

export type FileEventManagementOnFileUpdated<T = any> = {
    reference: FileEventManagementReference;
    data: T;
}

export class FileEventManagement<T = any> {
    static readonly OnFileAdded = new Observable<FileEventManagementOnFileAdded>();
    static readonly OnFileDeleted = new Observable<FileEventManagementOnFileDeleted>();
    static readonly OnFileUpdated = new Observable<FileEventManagementOnFileUpdated>();
}